import { URL } from "@/api/company";
import { URL as USER_URL } from "@/api/users";
import { URL as GOV_URL } from "@/api/gov_invoices_history.js";
import { validateEmail } from "@/plugins/rules.js";
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import { nextTick, ref } from "vue";
import { Form } from "vee-validate";
import * as yup from "yup";
import InputWithValidation from "@/components/inputWidthvalidate.vue";
import InputMoney from "@/components/inputMoney.vue";
import UserIcon from "@/components/UserIcon.vue";
import Status from "@/components/Status.vue";
import DVHCSelect from "@/components/DVHCSelect.vue";
import { checkTaxCode, checkTaxCodeRule } from "@/plugins/rules.js";
import searchInput from "@/components/searchInput";
import paginate from "@/components/paginate.vue";
import formatDate, { formatDateStandard } from "@/plugins/formatDate.js";
import { MSTTCGP_TEXT, LOAIDOANHNGHIEP_OPTIONS } from "../../../consts";
import nganhnghe_options from "@/assets/data/nganhnghe.json";

export default {
  components: {
    Form,
    InputWithValidation,
    InputMoney,
    searchInput,
    paginate,
    UserIcon,
    Status,
    DVHCSelect,
  },
  data() {
    return {
      tabDetailCompany: "EditCompany",
      showConfirmDeleteModal: false,
      showAddModal: false,
      showEditModal: false,
      targetCompany: {
        deductibleValue: 0,
      },
      editTargetCompany: {},
      companies: [],
      schema: {},
      search: "",
      paginate: {
        limit: null,
        totalItem: null,
        currentPage: 1,
        totalPage: 1,
      },
      dialogWidth: "30%",
      dialogWidthEdit: "50%",
      arrYear: [],
      isErrorVat22: false,
      isErrorVat22Edit: false,
      isDeactive: false,
      targetAddUser: {
        company_id: null,
        email: "",
      },
      selectOwner: null,
      listAllOwner: [],
      listAllOwnerSelect: [],
      isAddUser: false,
      showAddUserModal: false,
      rowSelected: null,
      isLoading: false,
      resetPasswordModal: false,
      ngayCap: "",
      latestData: {},

      advanceSearchVisible: false,
      advancedSearch: {
        city: null,
        district: null,
        ward: null,
        chuSoHuu: null,
        ngayCap: null,
        ngayDongMST: null,
        loaiDoanhNghiep: null,
        maNganhNghe: null,
        diaChiDangKyNopThue: null,
        haveGovPassword: false,
      },

      showGovPassword: false,
      resetHVHC: false,
      loaiDoanhNghiep_options: LOAIDOANHNGHIEP_OPTIONS,
      nganhnghe_options: nganhnghe_options,

      // Add users
      userForm: {
        roles: {},
      },
      roles: {},
      isActive: 'Active',
    };
  },
  setup() {
    yup.addMethod(yup.string, "taxCode", checkTaxCodeRule);

    const schema = yup.object({
      email: yup
        .string()
        .email(`Email không đúng định dạng`)
        .nullable()
        .label("Email address"),
      // tenDoanhNghiep: yup
      //   .string()
      //   .required("Tên doanh nghiệp là một trường bắt buộc")
      //   .typeError("Tên doanh nghiệp là một trường bắt buộc"),
      // webSite: yup.string().nullable().label("webSite"),
      // giamDoc: yup.string().nullable().label("Giám đốc"),
      // chuSoHuu: yup
      //   .string()
      //   .required("Đại diện pháp luật là một trường bắt buộc")
      //   .typeError("Đại diện pháp luật là một trường bắt buộc")
      //   .label("Đại diện pháp luật"),
      // tenGiaoDich: yup.string().nullable().label("Tên giao dịch"),
      // diaChiDangKyQuanLy: yup
      //   .string()
      //   .nullable()
      //   .label("Địa chỉ đăng ký quản lý"),
      // diaChiTruSo: yup.string().nullable().label("Địa chỉ trụ sở"),
      // diaChiChuSoHuu: yup.string().nullable().label("Địa chỉ chủ sở hữu"),
      // fax: yup.string().nullable().label("Fax"),
      // startYear: yup.string().nullable(),
      // ngayCap: yup.string().nullable().label("Ngày cấp").test("", "", function(value) {
      //   const { startYear } = this.parent
      //   const { path, createError } = this;
      //   return (value && (new Date(value)).getFullYear() <= parseInt(startYear)) || createError({ path, message: "Ngày nhập là bắt buộc và trước năm bắt đầu kê khai" });
      // }),
    });
    const mst = yup.object({
      mst: yup
        .string()
        .taxCode("Mã số thuế không hợp lệ")
        .required("Mã số thuế là một trường bắt buộc"),
    });
    const validateAddUser = yup.object({
      emailQuanLyTaiKhoan: yup
        .string()
        .required()
        .email("Email không đúng định dạng")
        .required("Email là một trường bắt buộc"),
    });
    const reset = ref(null);
    const resetAddMst = ref(null);
    const resetAddUser = ref(null);
    return {
      reset,
      resetAddMst,
      resetAddUser,
      schema,
      mst,
      validateAddUser,
    };
  },

  created() {
    const d = new Date();
    const m = d.getFullYear();
    for (let index = 0; index < 5; index++) {
      this.arrYear.push(m - index);
    }
    this.search = this.getCompanySearch;
    this.paginate = this.getCompanyPaginate;
    this.fetchCompanies();
    this.setDialogWidth();

    if (this.getAuthUser.is_itax_admin) {
      this.getAllOwner();
    }
  },

  computed: {
    ...mapGetters([
      "getCompanySearch",
      "getCompanyPaginate",
      "getSelectedCompany",
      "getAuthUser",
    ]),
    role() {
      return this.$store.state.setRoleUser.role;
    },
    vat_22() {
      return this.targetCompany.deductibleValue;
    },
    vat_22_edit() {
      return this.editTargetCompany.vat_22;
    },
    advancedSearchKeys() {
      const text_mapping = {
        city: "Địa chỉ trụ sở",
        chuSoHuu: "Đại diện pháp luật",
        ngayCap: "Ngày cấp",
        ngayDongMST: "Ngày đóng",
        loaiDoanhNghiep: "Loại doanh nghiệp",
        maNganhNghe: "Ngành nghề",
        diaChiDangKyNopThue: "Nơi đăng kí QLT",
        haveGovPassword: "Mật khẩu GOV",
      };
      return Object.keys(this.advancedSearch)
        .filter((item) => !!this.advancedSearch[item] && text_mapping[item])
        .map((item) => {
          return {
            key: item,
            text: text_mapping[item],
          };
        });
    },
    isPremiumUser() {
      return this.getAuthUser.plan == "premium" || true;
    },
    isSearchValidTaxcode() {
      return checkTaxCode(this.search);
    },
    showGovPass() {
      return (
        this.getAuthUser.is_itax_admin ||
        (this.editTargetCompany.owner &&
          this.getAuthUser.id == this.editTargetCompany.owner.id)
      );
    },
  },

  methods: {
    ...mapActions(["loadingLayer"]),

    async getDetailCompany(id_company) {
      const result = await this.$request({
        url: URL.EDIT_DETAIL_COMPANY.replace(":id", id_company),
      });
      const res = result.data;
      if (res.code == 200) {
        return res.data;
        // this.clickRow(res.data);
      }
    },

    resetValidate() {
      this.editTargetCompany = {};
      this.reset.resetForm();
    },

    resetFormAddMst() {
      this.resetAddMst.resetForm();
    },

    closeModalEdit() {
      this.tabDetailCompany = "EditCompany";
      this.selectOwner = null;
      this.arrYear = [];
      this.showEditModal = false;
      const d = new Date();
      const m = d.getFullYear();
      for (let index = 0; index < 5; index++) {
        this.arrYear.push(m - index);
      }
    },

    async fetchCompanies() {
      const param = {
        have_gov_password: this.advancedSearch.haveGovPassword,
        page: this.paginate.currentPage,
        limit: this.paginate.limit,
      };
      if (this.search) param.key_search = this.search;
      if (this.advancedSearch.city) param.city = this.advancedSearch.city;
      if (this.advancedSearch.district)
        param.district = this.advancedSearch.district;
      if (this.advancedSearch.ward) param.ward = this.advancedSearch.ward;
      if (this.advancedSearch.chuSoHuu)
        param.chuSoHuu = this.advancedSearch.chuSoHuu;
      if (this.advancedSearch.ngayCap)
        param.ngayCap = this.advancedSearch.ngayCap
          .map((item) => formatDateStandard(item))
          .join(",");
      if (this.advancedSearch.ngayDongMST)
        param.ngayDongMST = this.advancedSearch.ngayDongMST
          .map((item) => formatDateStandard(item))
          .join(",");
      if (this.advancedSearch.loaiDoanhNghiep)
        param.loaiDoanhNghiep = this.advancedSearch.loaiDoanhNghiep;
      if (this.advancedSearch.maNganhNghe)
        param.maNganhNghe = this.advancedSearch.maNganhNghe
          .split("-")[0]
          .trim();

      if (this.advancedSearch.diaChiDangKyNopThue) {
        param.diaChiDangKyNopThue = this.advancedSearch.diaChiDangKyNopThue
      }

      this.showGovPassword = this.advancedSearch.haveGovPassword;
      this.isLoading = true;
      const result = await this.$request({
        url: URL.LIST_COMPANY,
        params: param,
      });
      this.isLoading = false;

      const res = result.data;

      if (res.code == 200 && res.success) {
        this.companies = res.data.data;

        this.paginate.totalPage = res.data.total_pages;
        this.paginate.totalItem = res.data.total;
        this.paginate.limit = res.data.limit;
        this.$store.dispatch("setCompanyPaginate", this.paginate);
      }
    },

    async getAllOwner(keyword) {
      const params = { keyword };
      const result = await this.$request({
        url: URL.ALL_AVAILABLE_OWNER,
        params,
      });
      const res = result.data;
      if (res.code == 200) {
        this.listAllOwner = res.data;
        this.handleOwner();
      }
    },

    handleOwner() {
      this.listAllOwnerSelect = [...this.listAllOwner];
      const selectedCompany = this.latestData;

      if (selectedCompany.owner) {
        this.selectOwner = selectedCompany.owner.emailQuanLyTaiKhoan;

        this.listAllOwnerSelect = this.listAllOwnerSelect.filter(
          (item) => item.id != selectedCompany.owner.id
        );
        this.listAllOwnerSelect.unshift({
          id: selectedCompany.owner.id,
          emailQuanLyTaiKhoan: selectedCompany.owner.emailQuanLyTaiKhoan,
        });
      }
    },

    handleSelectOwnerBeforeSubmitUpdate(user) {
      this.listAllOwner.forEach((item) => {
        if (user === item.emailQuanLyTaiKhoan) {
          return item.id;
        }
      });
    },

    openCreateModal(mst = null) {
      const d = new Date();
      this.showAddModal = true;
      this.targetCompany = {};
      if (mst) {
        this.targetCompany.mst = mst;
      }
      this.targetCompany.startYear = d.getFullYear();
      this.targetCompany.declaration = "Quý";
      this.isErrorVat22 = false;
    },

    openEditModal(data) {
      this.advanceSearchVisible = false;
      this.isErrorVat22Edit = false;
      this.showEditModal = true;
      this.editTargetCompany = _.cloneDeep(data);
      this.ngayCap = "";
      if (this.editTargetCompany.ngayCap) {
        this.ngayCap = formatDate(this.editTargetCompany.ngayCap);
      }
      if (this.editTargetCompany.ngayDongMST) {
        this.editTargetCompany.ngayDongMST = formatDate(
          this.editTargetCompany.ngayDongMST
        );
      }

      this.editTargetCompany.msttcgp_text = "";
      if (
        this.editTargetCompany.msttcgp &&
        MSTTCGP_TEXT[this.editTargetCompany.msttcgp]
      ) {
        this.editTargetCompany.msttcgp_text = `${
          MSTTCGP_TEXT[this.editTargetCompany.msttcgp]
        } - ${this.editTargetCompany.msttcgp}`;
      }

      let y = new Date(this.editTargetCompany.ngayCap);
      this.arrYear = this.arrYear.filter((year) => {
        return year >= y.getFullYear();
      });
    },

    // Thêm người dùng
    async addUser(id_company) {
      this.targetAddUser.company_id = id_company;
      this.userForm = { roles: {} };
      this.roles = {};
      const code = await this.getListRoleUser(id_company);
      if (code == 200) {
        if (Object.keys(this.roles).length == 0) {
          this.$swal({
            title: "Vui lòng liên hệ chủ sở hữu tạo vai trò!",
            width: "330px",
            icon: "warning",
            position: "top",
            showConfirmButton: false,
            timerProgressBar: true,
            toast: true,
          });
        } else {
          this.isAddUser = true;
          this.showAddUserModal = true;
        }
      }

      // Select Member as Default ROle
      for (let id in this.roles) {
        if (this.roles[id]["name"] == "Member") {
          this.userForm.roles = this.roles[id]["id"];
        }
      }
    },

    cancelModalAddUser() {
      this.showAddUserModal = false;
      this.isAddUser = false;
      this.targetAddUser = {
        email: "",
        company_id: null,
      };
      this.resetAddUser.resetForm();
    },

    // Mời người dùng
    async confirmAddUser() {
      if (!validateEmail(this.userForm.emailQuanLyTaiKhoan)) {
        this.$swal({
          text: "Vui lòng nhập đúng định dạng email",
          icon: "warning",
        });
        return;
      }
      if (!this.userForm.roles) {
        this.$swal({ text: "Vui lòng chọn vai trò", icon: "warning" });
        return;
      }

      const data = {
        company_id: this.targetAddUser.company_id,
        role_ids: [this.userForm.roles],
        emailQuanLyTaiKhoan: this.userForm.emailQuanLyTaiKhoan,
      };
      const result = await this.$request({
        url: USER_URL.CREATE_USER,
        method: "POST",
        data,
      });
      const res = result.data;
      if (res.code == 200) {
        this.$swal({ text: "Tạo thành công" });
        this.fetchCompanies();

        this.showAddUserModal = false;
      }
      if (res.code == 400) {
        this.$swal({ text: res.messages, icon: "warning" });
      }
    },

    // Xóa người dùng
    async deleteUser(user_id) {
      this.$swal({
        title: "Xóa người dùng?",
        showCancelButton: true,
        confirmButtonText: "Xác nhận",
        cancelButtonText: "Hủy",
        confirmButtonColor: "#3b82f6",
        cancelButtonColor: "#adadad",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = {
            company_id: this.rowSelected,
            user_id: user_id,
          };
          const result = await this.$request({
            url: URL.DELETE_USER,
            method: "POST",
            data,
          });
          const res = result.data;
          if (res.code == 200) {
            this.$swal({ text: res.messages, icon: "success" });
            this.clickRow({ id: this.rowSelected });
            this.fetchCompanies();
          } else {
            this.$swal({ text: res.messages, icon: "error" });
          }
        }
      });
    },

    async clickRow(company) {
      this.rowSelected = company.id;
      company = await this.getDetailCompany(company.id);

      // Prepare data for user Tab
      company.owner["roles"] = [{ role__name: "Owner" }];
      this.isActive = company.is_active ? 'Active' : 'Deactive'
      company.users = [{ is_owner: true, ...company.owner }, ...company.users];
      this.latestData = company;
      this.handleOwner();
      this.openEditModal(company);
    },

    searchInput() {
      this.paginate.totalPage = 1;
      this.fetchCompanies();
    },

    focusInput() {
      const input = document.getElementById("input-search");
      input.focus();
      input.select();
    },

    handleCurrentPageChange($event) {
      this.paginate.currentPage = $event;
      this.fetchCompanies();
    },

    handleLimitUpdate(limit) {
      this.paginate.limit = limit;
      this.paginate.currentPage = 1;
      this.fetchCompanies();
    },

    async addCompany() {
      if (!this.isErrorVat22) {
        this.loadingLayer(true);
        const data = {};
        data.company_tax_code = this.targetCompany.mst;
        data.start_year = this.targetCompany.startYear;
        data.vat_22 = this.targetCompany.deductibleValue;
        if (!this.targetCompany.deductibleValue) {
          data.vat_22 = 0;
        }
        if (this.targetCompany.declaration == "Quý") data.kieu_ke_khai = "Q";
        else data.kieu_ke_khai = "T";

        const result = await this.$request({
          url: URL.ADD_COMPANY,
          method: "POST",
          data,
        });
        const res = result.data;

        if (res.code == 200 && res.success) {
          this.$swal({ text: "Tạo thành công" });
          this.fetchCompanies();
          this.showAddModal = false;
        }

        if (res.code == 400) {
          this.$swal({ text: res.errors[0].company_tax_code, icon: "error" });
        }
        this.loadingLayer(false);
      }
    },

    async editCompany() {
      if (!this.isErrorVat22Edit) {
        this.loadingLayer(true);

        const data = _.cloneDeep(this.editTargetCompany);
        delete data.ngayCap;
        delete data.ngayDongMST;

        data.owner = null;
        data.company_id = data.id;
        this.listAllOwnerSelect.forEach((item) => {
          if (this.selectOwner == item.emailQuanLyTaiKhoan) {
            data.owner = item.id;
          }
        });
        data.is_active = this.isActive == 'Active'
        const result = await this.$request({
          url: URL.EDIT_DETAIL_COMPANY.replace(":id", data.id),
          method: "PUT",
          data,
        });
        const res = result.data;

        if (res.code == 200 && res.success) {
          this.$swal({ text: "Cập nhật thành công" });
          this.fetchCompanies();
          this.showEditModal = false;
        }

        if (res.code == 400) {
          this.$swal({ text: Object.values(res.errors[0]), icon: "error" });
        }
        this.loadingLayer(false);
      }
    },

    statusCompany(value, id) {
      this.isDeactive = true;
      this.$swal({
        title: "Thay đổi trạng thái?",
        showCancelButton: true,
        confirmButtonText: "Xác nhận",
        cancelButtonText: "Hủy",
        confirmButtonColor: "#3b82f6",
        cancelButtonColor: "#adadad",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (value == "Active") {
            value = "Deactivate";
          } else value = "Active";

          const data = {};
          data.tinhTrang = value;

          const res = await this.$request({
            url: URL.EDIT_DETAIL_COMPANY.replace(":id", id),
            method: "PUT",
            data,
          });

          if (res.data.code == 200 && res.data.success) {
            this.$swal({
              text: "Cập nhật thành công",
              icon: "success",
              timer: "3000",
              width: "450",
              padding: "10px",
            });
            this.fetchCompanies();
          }
        }
        this.isDeactive = false;
      });
    },

    updateCompanyStatus() {
      this.$swal({
        title: "Cập nhật thông tin công ty",
        html: "<small><i>*Tác vụ có thể cần thời gian xử lý</i></small>",
        showCancelButton: true,
        confirmButtonText: "Xác nhận",
        cancelButtonText: "Hủy",
        confirmButtonColor: "#3b82f6",
        cancelButtonColor: "#adadad",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res = await this.$request({
            url: URL.LOOKUP_DETAIL_COMPANY.replace(":id", this.rowSelected),
            method: "POST",
          });

          if (res.data.code == 200 && res.data.success) {
            await this.fetchCompanies();
            this.clickRow(res.data.data);
          }
        }
      });
    },

    indexMethod(index) {
      return index + 1 + (this.paginate.currentPage - 1) * this.paginate.limit;
    },

    setDialogWidth() {
      let windowSize = document.body.clientWidth;

      if (windowSize < 1315 && windowSize >= 767) {
        this.dialogWidth = "40%";
      } else if (windowSize < 767) {
        this.dialogWidth = "90%";
        this.dialogWidthEdit = "90%";
      } else {
        this.dialogWidth = "30%";
        this.dialogWidthEdit = "50%";
      }
    },

    caculateRemainUsers(users = []) {
      let NUMBER_USERS_DISPLAY = 3;
      let remain = users.length - NUMBER_USERS_DISPLAY - 1;
      return [NUMBER_USERS_DISPLAY, remain <= 0 ? 0 : remain];
    },

    isMainClass({ row }) {
      return row.is_main ? "font-bold" : "";
    },
    ableToRenewPassword(expired_at) {
      if (expired_at) {
        let currentTime = new Date();
        // Reset pass 2 weeks before expired
        currentTime.setDate(currentTime.getDate() + 30);
        return new Date(expired_at) < currentTime;
      }

      return false;
    },
    async renew_password() {
      this.resetPasswordModal = true;
      this.$swal({
        title: "Gửi yêu cầu gia hạn mật khẩu",
        showCancelButton: true,
        confirmButtonText: "Xác nhận",
        cancelButtonText: "Hủy",
        confirmButtonColor: "#3b82f6",
        cancelButtonColor: "#adadad",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = { company_id: this.rowSelected };
          await this.$request({
            method: "PUT",
            url: GOV_URL.SYNC_GOV_INVOICES_HISTORY,
            isHideLoading: true,
            data,
          });
          await this.fetchCompanies();
        }
        this.resetPasswordModal = false;
      });
    },

    resetSearchCondition() {
      this.resetHVHC = !this.resetHVHC; // force changes

      this.advancedSearch = {
        city: null,
        district: null,
        ward: null,
        chuSoHuu: null,
        ngayCap: null,
        ngayDongMST: null,
        loaiDoanhNghiep: null,
        maNganhNghe: null,
        diaChiDangKyNopThue: null,
        haveGovPassword: false,
      };

      nextTick(() => {
        this.fetchCompanies();
      });
    },

    async handleRemoveLabelSearch(key) {
      this.paginate.totalPage = 1;
      this.advancedSearch[key] = null;

      if (key == "haveGovPassword") {
        this.advancedSearch[key] = false;
      }
      if (["city", "district", "ward"].indexOf(key) != -1) {
        this.resetHVHC = !this.resetHVHC; // force changes
        this.advancedSearch["city"] = null;
        this.advancedSearch["district"] = null;
        this.advancedSearch["ward"] = null;
      }

      nextTick(() => {
        this.fetchCompanies();
      });
    },

    searchLabelTransform(key) {
      if (key == "haveGovPassword") {
        return "Mở";
      }

      if (["city", "district", "ward"].indexOf(key) != -1) {
        let result = this.advancedSearch["city"];
        if (this.advancedSearch["district"]) {
          result += ", " + this.advancedSearch["district"];
        }
        if (this.advancedSearch["ward"]) {
          result += ", " + this.advancedSearch["ward"];
        }
        return result;
      }

      if (key == "ngayCap" || key == "ngayDongMST") {
        return this.advancedSearch[key]
          .map((item) => formatDate(item))
          .join(" ~ ");
      }

      return this.advancedSearch[key];
    },

    async getListRoleUser(company_id) {
      const params = {
        company_id,
      };
      const result = await this.$request({
        url: USER_URL.ROLES_USER,
        params,
      });
      const res = result.data;

      if (res.success && res.code == 200) {
        this.roles = res.data;
      }
      return res.code;
    },

    removeOwner() {
      this.$swal({
        text: "Xác nhận xóa Owner",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Tiếp tục",
        reverseButtons: true,
        cancelButtonText: "Hủy",
        timer: null,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.selectOwner = null;
        }
      });
    },

    formatDate,
    checkTaxCode,
    formatDateStandard,
  },

  mounted() {
    window.onresize = () => {
      return (() => {
        this.setDialogWidth();
      })();
    };
  },

  watch: {
    vat_22(newVal) {
      if (newVal) {
        this.isErrorVat22 = newVal.length > 18;
      }
    },
    vat_22_edit(newVal) {
      if (newVal) {
        this.isErrorVat22Edit = newVal.length > 18;
      }
    },
    getCompanySearch(newVal) {
      this.search = newVal;
      this.searchInput();
    },
  },
};
