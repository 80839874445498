<div
  class="flex justify-between align-middle gap-2 mb-2 items-center"
  v-if="role != 'CompanyUser' && role != 'CompanyAdmin' && role != 'CompanySuperAdminSub'"
>
  <!-- Advanced Search -->
  <div class="flex items-center" >
    <el-popover :visible="advanceSearchVisible" placement="right-start" :width="750" close-delay="0">
      <!-- Search content -->
      <div class="items-center gap-2 m-2 relative">
        <!-- layer for premium plan alert -->
        <el-tooltip
          class="item"
          effect="dark"
          content="Yêu cầu tải khoản premium. Vui lòng liên hệ clientcare@itaxvn.com để được hỗ trợ"
          placement="right"
          v-if="!isPremiumUser"
        >
          <div
            class="overlay absolute w-full h-full bg-gray-200 z-50 p-5 opacity-80 flex justify-center items-center flex-col"
          >
            <i class="el-icon-lock text-5xl" />
            <span class="font-bold text-xl text-center break-normal"
              >Yêu cầu tải khoản premium. Vui lòng liên hệ clientcare@itaxvn.com để
              được hỗ trợ</span
            >
          </div>
        </el-tooltip>

        <!-- Địa chỉ trụ sở -->
        <DVHCSelect
          :reset="resetHVHC"
          @city:update="advancedSearch.city=$event"
          @district:update="advancedSearch.district=$event"
          @ward:update="advancedSearch.ward=$event"
        />

        <!-- Đại diện pháp luật -->
        <div class="flex items-center justify-start mb-2">
          <span class="w-44 pr-2 break-normal">Đại diện pháp luật</span>
          <div class="w-full">
            <el-input class="w-full" v-model="advancedSearch.chuSoHuu" />
          </div>
        </div>

        <!-- Ngày cấp MST -->
        <div class="flex items-center justify-start mb-2">
          <span class="w-44 pr-2 break-normal">Ngày cấp MST</span>
          <div class="w-full">
              <el-date-picker
                style="width: 100%;"
                v-model="advancedSearch.ngayCap"
                type="daterange"
                start-placeholder="Ngày bắt đầu"
                end-placeholder="Ngày kết thúc"
                format="DD/MM/YYYY"
                :disabled-date="disabledDate"
                @change="handleRequestSyncDate"
                class="w-full"
              />
          </div>
        </div>

        <!-- Ngày đóng MST -->
        <div class="flex items-center justify-start mb-2">
          <span class="w-44 pr-2 break-normal">Ngày đóng MST</span>
          <div class="w-full">
              <el-date-picker
                style="width: 100%;"
                v-model="advancedSearch.ngayDongMST"
                type="daterange"
                start-placeholder="Ngày bắt đầu"
                end-placeholder="Ngày kết thúc"
                format="DD/MM/YYYY"
                :disabled-date="disabledDate"
                @change="handleRequestSyncDate"
                class="w-full"
              />
          </div>
        </div>

        <!-- Loại hình pháp lý -->
        <div class="flex items-center justify-start mb-2">
          <span class="w-44">Loại hình pháp lý</span>
          <div class="w-full">
            <el-select
              class="w-full"
              v-model="advancedSearch.loaiDoanhNghiep"
              placeholder="Nhập để lựa chọn..."
              filterable
              clearable
            >
              <el-option
                v-for="(item, index) in loaiDoanhNghiep_options"
                :value="item"
                :key="index"
              >
                {{ item }}
              </el-option>
            </el-select>
          </div>
        </div>

        <!-- Ngành nghề -->
        <div class="flex items-center justify-start mb-2">
          <span class="w-44">Ngành nghề</span>
          <div class="w-full">
            <el-select
              class="w-full"
              v-model="advancedSearch.maNganhNghe"
              placeholder="Nhập để lựa chọn..."
              filterable
              clearable
            >
              <el-option
                v-for="(item, index) in nganhnghe_options"
                :value="`${item.code} - ${item.name}`"
                :key="index"
              >
                <div class="max-w-3xl overflow-auto">
                  {{ item.code }} - {{ item.name }}
                </div>
              </el-option>
            </el-select>
          </div>
        </div>

        <!-- Đại diện pháp luật -->
        <div class="flex items-center justify-start mb-2">
          <span class="w-44 pr-2 break-normal">Nơi đăng kí QLT</span>
          <div class="w-full">
            <el-input class="w-full" v-model="advancedSearch.diaChiDangKyNopThue" />
          </div>
        </div>

        <!-- GOV password -->
        <div class="flex items-center justify-start mb-2" v-if="getAuthUser.is_itax_admin">
          <span class="w-32 mr-4">Mật khẩu GOV</span>
          <div>
            <el-checkbox class="h-4" v-model="advancedSearch.haveGovPassword"/>
          </div>
        </div>

      </div>
      <!-- End of search content -->

      <hr>

      <!-- popover footer -->
      <div class="text-right mt-3">
        <el-button size="mini" @click="advanceSearchVisible = false" icon="el-icon-back" >Đóng</el-button>
        <el-button size="mini" @click="resetSearchCondition" icon="el-icon-close">Xoá</el-button>
        <el-button size="mini" type="primary" @click="() => {paginate.totalPage=1;advanceSearchVisible=false;fetchCompanies()}" icon="el-icon-search">Tìm kiếm</el-button>
      </div>

      <!-- Advance search button -->
      <template #reference>
        <el-button @click="advanceSearchVisible=true" icon="el-icon-setting" type="secondary" size="small">Tìm kiếm nâng cao</el-button>
      </template>

    </el-popover>
  </div>
  <!-- end of Advanced Search -->

  <div class="w-full h-full flex-wrap ml-2">
    <span
      v-for="(searchCondition, index) in advancedSearchKeys"
      :key="index"
      class="inline-block cursor-pointer relative search-label mr-1"
      @click="handleRemoveLabelSearch(searchCondition.key)"
    >
      <span class="absolute z-10 bg-gray-300 w-full h-full text-center opacity-80 text-xl">
        <i class="el-icon-error text-gray-900"/>
      </span>
      <div class="search-label-content">
        <div class="flex justify-start gap-1">
          <div> <i class="el-icon-error text-white"/> </div>
          {{ searchCondition.text }}
          <div class="px-1 rounded bg-gray-600 max-w-xs overflow-hidden truncate">{{ searchLabelTransform(searchCondition.key) }} </div>
        </div>
      </div>
    </span>
  </div>

  <el-button
    class="h-4 mt-2 my-1 ml-0"
    size="mini"
    type="primary"
    icon="el-icon-plus"
    @click="openCreateModal()"
  >
    Thêm công ty
  </el-button>
</div>

<!-- Danh sách công ty -->
<el-scrollbar class="height-table" v-loading="isLoading">
  <el-table
    :data="companies"
    border
    stripe
    empty-text="Không có dữ liệu"
    :header-cell-style="{ background: 'white' }"
    @row-click="clickRow($event)"
  >
      <el-table-column
        type="index"
        label="STT"
        width="80"
        :index="indexMethod"
        align="center"
      />
      <el-table-column
        prop="tenDoanhNghiep"
        label="Tên doanh nghiệp"
        min-width="300"
      >
        <template #default="prop">
          <div class="flex justify-between items-center gap-2">
            <span>{{ prop.row.tenDoanhNghiep }}</span>
            <Status :status="prop.row.status" />
          </div>
        </template>
      </el-table-column>
      
      <el-table-column width="280" label="Tài khoản">
        <template #default="scope">
          <div class="flex justify-between items-center">

            <!-- user icon/avatar group -->
            <div class="flex items-center justify-center" @click="tabDetailCompany = 'AddUser'" >
              <span v-for="(user, index) in scope.row.users" :key="index">
                <UserIcon :user="user" v-if="index <= caculateRemainUsers(scope.row.users)[0]"/>
              </span>
              <UserIcon :user="{emailQuanLyTaiKhoan: `+${caculateRemainUsers(scope.row.users)[1]}`}" v-if="scope.row.users && caculateRemainUsers(scope.row.users)[1]" class="opacity-70"/>
            </div>

            <span class="h-full" @click.stop.prevent="addUser(scope.row.id)">
              <el-button size="mini" class="w-14">
                <div class="flex items-center justify-center h-5">
                  <i class="el-icon-user" />
                  <span>Mời</span>
                </div>
              </el-button>
            </span>

          </div>
        </template>
      </el-table-column>
      <el-table-column
        width="150"
        label="Ngày hết hạn mật khẩu"
        align="center"
        v-if="showGovPassword"
      >
        <template #default="scope">

          <div class="flex justify-center flex-col" v-if="scope.row.gov_invoice_password_expired_at">
            <span v-if="scope.row.gov_invoice_password_expired_at != '2100-01-01T00:00:00'">{{ formatDate(scope.row.gov_invoice_password_expired_at) }}</span>
            <span v-else>Không xác định</span>

            <el-tooltip
              v-if="scope.row.gov_invoice_password_message"
              effect="dark"
              :content="scope.row.gov_invoice_password_message"
              placement="left"
            >
              <el-alert :closable="false" title="Cảnh báo" type="error" effect="dark" show-icon> </el-alert>
            </el-tooltip>
          </div>
          <el-button @click="renew_password" type="warning" icon="el-icon-warning-outline" size="mini" v-if="ableToRenewPassword(scope.row.gov_invoice_password_expired_at) && !scope.row.gov_invoice_password_message">
            Gia hạn
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        prop="mst"
        label="Mã số thuế"
        align="center"
        width="160"
      >
        <template #default="scope">
          <div class="flex justify-center">
            <span class="mr-2">{{ scope.row.mst }}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        prop="emailQuanLyHoaDon"
        label="Email quản lý hóa đơn"
        width="240"
      />
      <!-- <el-table-column -->
      <!--   v-if="role != 'CompanyUser' && role != 'CompanyAdmin'" -->
      <!--   prop="tinhTrang" -->
      <!--   label="Trạng thái" -->
      <!--   align="center" -->
      <!--   width="110" -->
      <!-- > -->
      <!--   <template #default="scope"> -->
      <!--     <p -->
      <!--       @click="statusCompany(scope.row.tinhTrang, scope.row.id)" -->
      <!--       class="cursor-pointer" -->
      <!--       :class="[{ textSuccess: scope.row.tinhTrang == 'Active' }, {textDanger: scope.row.tinhTrang == 'Deactivate'}]" -->
      <!--     > -->
      <!--       {{ scope.row.tinhTrang }} -->
      <!--     </p> -->
      <!--   </template> -->
      <!-- </el-table-column> -->
      <el-table-column label="" align="center" width="50">
        <template #header> </template>
        <template #default="scope">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit"
            circle
            @click="openEditModal(scope.row)"
          >
          </el-button>
        </template>
      </el-table-column>

    <!-- Custom empty text -->
    <template #empty>
      <div>
        <span v-if="!isSearchValidTaxcode">Không có dữ liệu</span>
        <span v-else>
          <el-button
            size="mini"
            type="warning"
            icon="el-icon-plus"
            @click="openCreateModal(search)"
          >
            Công ty chưa có dữ liệu.
            Vui lòng chọn nút này để tạo công ty: {{ search }}
          </el-button>
        </span>
      </div>
    </template>

  </el-table>
</el-scrollbar>

<!-- Phân trang -->
<div>
  <paginate
    :currentPage="paginate.currentPage"
    :totalItem="paginate.totalItem"
    :totalPage="paginate.totalPage"
    :limit="paginate.limit"
    @modifiedCurrentPage="handleCurrentPageChange($event)"
    @update:limit="handleLimitUpdate"
  />
</div>

<!-- Modal thêm công ty -->
<el-dialog
  title="Thêm công ty"
  v-model="showAddModal"
  :width="dialogWidth"
  @closed="resetFormAddMst()"
>
  <Form
    as="el-form"
    :validation-schema="mst"
    ref="resetAddMst"
    @submit="addCompany()"
    :label-position="dialogWidth == '30%' ? 'left':'top'"
  >
    <el-form-item class="w-full" label="Mã số thuế" label-width="160px">
      <InputWithValidation
        type="text"
        label="Mã số thuế"
        v-model="targetCompany.mst"
        name="mst"
        :isNoSpace="true"
      />
    </el-form-item>

    <el-form-item
      class="w-full"
      label="Năm bắt đầu kê khai"
      label-width="160px"
    >
      <el-select
        class="w-full"
        v-model="targetCompany.startYear"
        placeholder="Kỳ kê khai"
      >
        <el-option v-for="(item, index) in arrYear" :value="item" :key="index">
          {{item}}
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item class="w-full" label="Kỳ kê khai" label-width="160px">
      <el-select
        class="w-full"
        v-model="targetCompany.declaration"
        placeholder="Kỳ kê khai"
      >
        <el-option value="Quý"> Quý </el-option>
        <el-option value="Tháng"> Tháng </el-option>
      </el-select>
    </el-form-item>
    <el-form-item
      class="w-full input-money relative"
      :class="isErrorVat22 ? 'border-error': ''"
      label="Giá trị khấu trừ kỳ đầu"
      label-width="160px"
    >
      <input-money
        v-model="targetCompany.deductibleValue"
        class="money"
        :invoiceStyle="false"
        :negative="true"
        :decima="0"
        @inputChange="targetCompany.deductibleValue = $event"
      />
      <span v-if="isErrorVat22" class="absolute block text-xs text-red-400">
        Số tiền quá 18 ký tự
      </span>
    </el-form-item>
    <div class="text-right itax-button">
      <span class="dialog-footer">
        <el-button size="mini" @click="showAddModal = false"> Hủy </el-button>
        <el-button
          size="mini"
          type="primary"
          native-type="submit"
          :disabled="!targetCompany.mst || isErrorVat22"
        >
          Xác nhận
        </el-button>
      </span>
    </div>
  </Form>
</el-dialog>

<el-dialog title="Thêm người dùng" v-model="showAddUserModal">
  <el-form @submit.prevent="() => {}">
    <el-form-item class="w-full relative" label="Email" label-width="70px">
      <el-input
        v-model="userForm.emailQuanLyTaiKhoan"
        name="hoTen"
        required
      />
      <span v-if="isEmail" class="absolute text-xs text-red-400">
        {{ errorEmail }}
      </span>
    </el-form-item>

    <el-form-item class="w-full" label="Vai trò" label-width="70px">
      <el-radio-group v-model="userForm.roles" class="grid grid-cols-3">
        <el-radio
          v-for="(role, idx) in roles"
          :key="idx"
          :label="role['id']"
        >
          {{ role["name"] }}
        </el-radio>
      </el-radio-group>
    </el-form-item>

    <div class="dialog-footer itax-button text-right">
      <el-button size="mini" @click="showAddUserModal = false">
        Hủy
      </el-button>
      <el-button size="mini" type="primary" @click="confirmAddUser">
        Xác nhận
      </el-button>
    </div>
  </el-form>
</el-dialog>

<!-- modal chỉnh sửa và danh sách người dùng -->
<el-dialog
  top="2vh"
  v-model="showEditModal"
  width="80%"
  @closed="closeModalEdit()"
  modal-class="company-detail-modal"
>
  <el-tabs v-model="tabDetailCompany">
    <el-tab-pane label="Quản lý" name="EditCompany"/>
    <el-tab-pane label="Ngành Nghề" name="nganhNghe"/>
    <el-tab-pane label="Người dùng" name="AddUser"/>
    <el-tab-pane label="DN Chủ Quản" name="dn_chuquan"/>
    <el-tab-pane label="ĐV Thành Viên" name="dv_thanhvien"/>
    <el-tab-pane label="ĐV Trực Thuộc" name="dv_tructhuoc"/>
    <el-tab-pane label="VP Đại Diện" name="vp_daidien"/>
    <el-tab-pane label="Loại Thuế Phải Nộp" name="loai_thue_can_nop"/>
  </el-tabs>

  <!-- Tab Quản lý công ty -->
  <Form
    v-if="tabDetailCompany == 'EditCompany'"
    as="el-form"
    :validation-schema="schema"
    @submit="editCompany(null)"
    ref="reset"
  >
    <el-form
      :label-position="dialogWidthEdit == '50%' ? 'left':'top'"
      :inline="true"
      width="100%"
      class="detail-company"
    >
      <h1>Thông tin chung</h1>
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item class="w-full" label="Mã số thuế">
            <el-input v-model="editTargetCompany.mst" autocomplete="off" clearable disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item class="w-full" label="Ngày cấp">
            <el-input v-model="ngayCap" autocomplete="off" clearable disabled :class="{'highlight-search': advancedSearch.ngayCap}"/>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item class="w-full" label="Ngày đóng MST">
            <el-input v-model="editTargetCompany.ngayDongMST" autocomplete="off" clearable disabled :class="{'highlight-search': advancedSearch.ngayDongMST}"/>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item class="w-full" label="Tên chính thức">
            <el-input v-model="editTargetCompany.tenDoanhNghiep" autocomplete="off" type="textarea" autosize disabled :class="{'highlight-search': search}"/>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item class="w-full" label="Tên giao dịch">
            <el-input v-model="editTargetCompany.tenGiaoDich" autocomplete="off" clearable disabled :class="{'highlight-search': search}"/>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item class="w-full" label="Nơi đăng ký quản lý thuế">
            <el-input v-model="editTargetCompany.diaChiDangKyQuanLy" autocomplete="off" clearable disabled />
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item class="w-full" label="Địa chỉ trụ sở">
            <el-input v-model="editTargetCompany.diaChiTruSo" autocomplete="off" clearable disabled :class="{'highlight-search': advancedSearch.city || advancedSearch.district || advancedSearch.ward}"/>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item class="w-full" label="Nơi đăng ký nộp thuế">
            <el-input v-model="editTargetCompany.diaChiDangKyNopThue" autocomplete="off" clearable disabled />
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item class="w-full" label="Địa chỉ nhận thông báo thuế">
            <el-input v-model="editTargetCompany.diaChiNhanThongBaoThue" autocomplete="off" clearable disabled />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
       <el-col :span="8">
          <el-form-item class="w-full" label="QĐTL-Ngày cấp">
            <el-input v-model="editTargetCompany.qdtlNgayCap" autocomplete="off" clearable disabled />
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item class="w-full" label="Cơ quan ra quyết định">
            <el-input v-model="editTargetCompany.coQuanRaQuyetDinh" autocomplete="off" clearable disabled />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
       <el-col :span="8">
          <el-form-item class="w-full" label="GPKD-Ngày cấp">
            <el-input v-model="editTargetCompany.gpkdNgayCap" autocomplete="off" clearable disabled />
          </el-form-item>
        </el-col>
       <el-col :span="8">
          <el-form-item class="w-full" label="Cơ quan cấp">
            <el-input v-model="editTargetCompany.coQuanCap" autocomplete="off" clearable disabled />
          </el-form-item>
        </el-col>
       <el-col :span="8">
          <el-form-item class="w-full" label="Ngày nhận tờ khai">
            <el-input v-model="editTargetCompany.ngayNhanToKhai" autocomplete="off" clearable disabled />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
       <el-col :span="8">
          <el-form-item class="w-full" label="Ngày/tháng bắt đầu năm tài chính">
            <el-input v-model="editTargetCompany.ngayBatDauNamTaiChinh" autocomplete="off" clearable disabled />
          </el-form-item>
        </el-col>
       <el-col :span="8">
          <el-form-item class="w-full" label="Ngày/tháng kết thúc năm tài chính">
            <el-input v-model="editTargetCompany.ngayKetThucNamTaiChinh" autocomplete="off" clearable disabled />
          </el-form-item>
        </el-col>
       <el-col :span="8">
          <el-form-item class="w-full" label="Mã số hiện thời">
            <el-input v-model="editTargetCompany.maSoHienThoi" autocomplete="off" clearable disabled />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
       <el-col :span="24">
          <el-form-item class="w-full" label="Ngày bắt đầu HĐ">
            <el-input v-model="editTargetCompany.ngayBatDauHD" autocomplete="off" clearable disabled />
          </el-form-item>
       </el-col>
      </el-row>

      <el-row :gutter="10">
       <el-col :span="8">
          <el-form-item class="w-full" label="Chương – Khoản">
            <el-input v-model="editTargetCompany.chuongKhoan" autocomplete="off" clearable disabled />
          </el-form-item>
        </el-col>
       <el-col :span="8">
          <el-form-item class="w-full" label="Hình thức h.toán">
            <el-input v-model="editTargetCompany.hinhThucHToan" autocomplete="off" clearable disabled />
          </el-form-item>
        </el-col>
       <el-col :span="8">
          <el-form-item class="w-full" label="PP tính thuế GTGT">
            <el-input v-model="editTargetCompany.ppTinhThueGTGT" autocomplete="off" clearable disabled />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
       <el-col :span="8">
          <el-form-item class="w-full" label="Chủ sở hữu/Người đại diện pháp luật">
            <el-input v-model="editTargetCompany.chuSoHuu" autocomplete="off" clearable disabled :class="{'highlight-search': advancedSearch.chuSoHuu}"/>
          </el-form-item>
        </el-col>
       <el-col :span="16">
          <el-form-item class="w-full" label="Địa chỉ chủ sở hữu/người đại diện pháp luật">
            <el-input v-model="editTargetCompany.diaChiChuSoHuu" autocomplete="off" clearable disabled />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
       <el-col :span="8">
          <el-form-item class="w-full" label="Tên giám đốc">
            <el-input v-model="editTargetCompany.giamDoc" autocomplete="off" clearable disabled />
          </el-form-item>
        </el-col>
       <el-col :span="16">
          <el-form-item class="w-full" label="Địa Chỉ">
            <el-input v-model="editTargetCompany.diaChiGiamDoc" autocomplete="off" clearable disabled />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
       <el-col :span="8">
          <el-form-item class="w-full" label="Kế toán trưởng">
            <el-input v-model="editTargetCompany.keToanTruong" autocomplete="off" clearable disabled />
          </el-form-item>
        </el-col>
       <el-col :span="16">
          <el-form-item class="w-full" label="Địa Chỉ">
            <el-input v-model="editTargetCompany.diaChiKeToanTruong" autocomplete="off" clearable disabled />
          </el-form-item>
        </el-col>
      </el-row>

      <hr class="mb-2">
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item class="w-full" label="Ngày thay đổi thông tin gần nhất" v-if="editTargetCompany.id">
            <el-date-picker
              style="width: 100%"
              v-model="editTargetCompany.gov_updated_at"
              type="date"
              placeholder="Công ty chưa được cập nhật thông tin từ GOV"
              format="DD/MM/YYYY"
              :disabled="true"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item class="w-full" label="Số điện thoại" >
            <el-input v-model="editTargetCompany.soDienThoai" autocomplete="off" clearable disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item class="w-full" label="Loại doanh nghiệp" >
            <el-input v-model="editTargetCompany.loaiDoanhNghiep" autocomplete="off" clearable disabled  :class="{'highlight-search': this.advancedSearch.loaiDoanhNghiep}"/>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
       <el-col :span="8">
          <el-form-item class="w-full" label="Trạng thái công ty">
            <template #label>
              <div class="flex justify-between items-center gap-2">
                <span>Trạng thái công ty</span>
                <Status :status="editTargetCompany.status" placement="bottom"/>
              </div>
            </template>
            <el-input
              v-model="editTargetCompany.status"
              autocomplete="off"
              disabled
            />
          </el-form-item>
        </el-col>
       <el-col :span="16">
          <el-form-item class="w-full" label="Hoá đơn điện tử">
            <el-input
              v-model="editTargetCompany.msttcgp_text"
              autocomplete="off"
              disabled
            />
          </el-form-item>
        </el-col>
      </el-row>

      <hr class="mb-3">
      <h1>Thông tin iTax</h1>
      <el-row :gutter="10">
         <el-col :span="8">
          <el-form-item class="w-full" label="Email quản lý hóa đơn">
            <el-input
              v-model="editTargetCompany.emailQuanLyHoaDon"
              autocomplete="off"
              disabled
            />
            </el-form-item>
          </el-col>
       <el-col :span="8">
          <el-form-item class="w-full">
            <template #label>
              <div class="flex justify-between items-center gap-2">
                <p class="leading-5">Owner</p>
                <!-- Hide -->
                <span class="text-red-500 font-bold text-lg cursor-pointer" @click="removeOwner" v-show="getAuthUser.is_itax_admin && editTargetCompany.owner.id">
                  <i class="el-icon-delete" />
                </span>
              </div>
            </template>
            <el-select
              class="w-full"
              v-model="selectOwner"
              :disabled="!getAuthUser.is_itax_admin"
              placeholder="Chọn tài khoản sở hữu..."
              filterable
              :remote-method="getAllOwner"
              remote
            >
              <el-option
                v-for="(item, index) in listAllOwnerSelect"
                :value="item.emailQuanLyTaiKhoan"
                :key="index"
              >
                {{item.emailQuanLyTaiKhoan}}
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
       <el-col :span="8" v-if="getAuthUser.is_itax_admin" >
          <el-form-item class="w-full" label="Tình trạng">
            <el-select
              class="w-full"
              v-model="isActive"
              placeholder="Tình trạng"
            >
              <el-option value="Active"> </el-option>
              <el-option value="Deactive"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
         <el-col :span="8">
          <el-form-item class="w-full" label="Năm bắt đầu kê khai" >
            <el-select
              class="w-full"
              v-model="editTargetCompany.start_year"
              placeholder="Năm bắt đầu kê khai"
              :disabled="editTargetCompany.is_disable_vat_22"
            >
              <el-option
                v-for="(item, index) in arrYear"
                :value="item"
                :key="index"
              >
                {{item}}
              </el-option>
            </el-select>
          </el-form-item>
          </el-col>
       <el-col :span="8">
          <el-form-item class="w-full" label="Kỳ kê khai">
            <el-select
              class="w-full"
              v-model="editTargetCompany.kieuKeKhai"
              :disabled="editTargetCompany.is_disable_kieukekhai"
            >
              <el-option
                v-for="(item, index) in ['Q', 'T']"
                :value="item"
                :key="index"
              >
                {{ item == "Q" ? "Quý" : "Tháng" }}
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
       <el-col :span="8">
          <el-form-item
            class="w-full input-money relative"
            :class="isErrorVat22Edit ? 'border-error': ''"
            label="Giá trị khấu trừ kỳ đầu"
          >
            <input-money
              v-model="editTargetCompany.vat_22"
              class="money"
              :invoiceStyle="false"
              :decima="0"
              :disabled="editTargetCompany.is_disable_vat_22"
              @inputChange="editTargetCompany.vat_22 = $event"
            />
            <span
              v-if="isErrorVat22Edit"
              class="absolute block text-xs text-red-400"
            >
              Số tiền quá 18 ký tự
            </span>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
         <el-col :span="8">
          <el-form-item class="w-full" label="Ngày tạo trên hệ thống" v-if="editTargetCompany.id">
            <el-date-picker
              style="width: 100%"
              v-model="editTargetCompany.ngayTao"
              type="date"
              placeholder="Ngày tạo trên hệ thống"
              format="DD/MM/YYYY"
              :disabled="true"
            >
            </el-date-picker>
          </el-form-item>
          </el-col>

       <el-col :span="8">
          <el-form-item class="w-full" label="Ngày cập nhật" v-if="editTargetCompany.id">

            <div
              class="flex justify-between h-full gap-2"
              v-if="!editTargetCompany.is_updating_info"
            >
              <el-date-picker
                style="width: 100%"
                v-model="editTargetCompany.updated_at"
                type="date"
                placeholder=""
                format="DD/MM/YYYY hh:mm:ss"
                :disabled="true"
              />
              <el-button class="update_btn w-24 h-full ml-2" v-if="!editTargetCompany.is_updating_info" size="mini" icon="el-icon-warning" type="warning" @click="updateCompanyStatus">
                Cập nhật
              </el-button>
            </div>

            <el-input v-else placeholder="Hệ thống đang cập nhật..." disabled value="Hệ thống đang cập nhật..." />

          </el-form-item>
        </el-col>



       <el-col :span="8" v-if="showGovPass">
          <el-form-item class="w-full">
            <template #label>
              <p class="leading-5">Mật khẩu<small class="block">hoadondientu.gdt.gov.vn</small></p>
            </template>
            <el-input
              placeholder="Mật khẩu hoadondientu.gdt.gov.vn"
              v-model="editTargetCompany.gov_invoice_password"
              type="password"
              show-password
              :autocomplete="'new-password'"
              :class="{'highlight-search': showGovPassword}"
            />
          </el-form-item>
        </el-col>
      </el-row>

    </el-form>
    <div class="text-right itax-button mt-1">
      <span class="dialog-footer">
        <el-button size="mini" @click="closeModalEdit()"> Hủy </el-button>
        <el-button size="mini" type="primary" native-type="submit">
          Xác nhận
        </el-button>
      </span>
    </div>
  </Form>

  <!-- Tab thêm người dùng -->
  <div v-if="tabDetailCompany == 'AddUser'">

    <!-- Performance Problem => Stop -->
    <div class="text-right mb-3" v-if='false'>
      <el-button size="mini" @click="addUser(editTargetCompany.id)">
        <div class="flex items-center justify-center">
          <img
            src="@/assets/icons/icon_add_user.svg"
            alt=""
            class="w-4 h-4 mr-1"
          />
          <span>Thêm người dùng</span>
        </div>
      </el-button>
    </div>

    <el-table :data="editTargetCompany.users" border style="width: 100%">
      <el-table-column
        type="index"
        label="STT"
        width="50"
        align="center"
      />
      <el-table-column
        prop="emailQuanLyTaiKhoan"
        label="Email quản lý tài khoản"
        width="300"
      >
        <template #default="props">
          <div class="flex justify-between items-center">
            {{props.row.emailQuanLyTaiKhoan}}
            <UserIcon :user="props.row" />
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="Vai trò"
        width="350"
        align="left"
        v-if="getSelectedCompany.id != -1"
      >
        <template #default="props">
          {{
            props.row.roles
              ? props.row.roles.map((item) => item["role__name"]).join(", ")
              : ""
          }}
        </template>
      </el-table-column>
      <el-table-column prop="hoTen" label="Họ tên" />

      <el-table-column v-if="false" width="60" align="center">
        <template #default="props">
          <div
            @click="deleteUser(props.row.id)"
          >
            <img
              src="@/assets/icons/times.svg"
              alt=""
              class="w-4 h-4 block mx-auto"
            />
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="text-right itax-button mt-5">
      <span class="dialog-footer">
        <el-button size="mini" @click="showEditModal = false"> Đóng </el-button>
      </span>
    </div>
  </div>


  <!-- Tab DN chủ quản -->
  <div v-if="tabDetailCompany == 'dn_chuquan'">
    <el-scrollbar class="height-table">
      <el-table :data="editTargetCompany.dn_chuquan" border :row-class-name="isMainClass" >
        <el-table-column type="index" label="STT" width="70" align="center" />
        <el-table-column prop="mst" align="center" label="MST" width="150"/>
        <el-table-column prop="dn_chuquan" label="DN Chủ Quản" />
        <el-table-column prop="diachi" label="Địa Chỉ" />
        <el-table-column prop="gtgt" label="GTGT" width="150" align="center"/>
      </el-table>
    </el-scrollbar>
  </div>

  <!-- Tab ĐV thành viên -->
  <div v-if="tabDetailCompany == 'dv_thanhvien'">
    <el-scrollbar class="height-table">
      <el-table :data="editTargetCompany.dv_thanhvien" border>
        <el-table-column type="index" label="STT" width="70" align="center" />
        <el-table-column prop="mst" align="center" label="MST" width="150"/>
        <el-table-column prop="ten_thanh_vien" label="Tên Thành Viên" />
        <el-table-column prop="diachi" label="Địa Chỉ" />
        <el-table-column prop="ma_tinh" label="Mã Tỉnh" />
        <el-table-column prop="ma_quan_huyen" label="Mã Quận/Huyện" />
        <el-table-column prop="ten_tinh" label="Tên Tỉnh" />
        <el-table-column prop="ten_quan_huyen" label="Tên Quận/Huyện" />
      </el-table>
    </el-scrollbar>
  </div>

  <!-- Tab ĐV Trực thuộc -->
  <div v-if="tabDetailCompany == 'dv_tructhuoc'">
    <el-scrollbar class="height-table">
      <el-table :data="editTargetCompany.dv_tructhuoc" border :row-class-name="isMainClass" >
        <el-table-column type="index" label="STT" width="70" align="center" />
        <el-table-column prop="mst" align="center" label="MST" width="150"/>
        <el-table-column prop="ten_don_vi" label="Tên Đơn Vị" />
        <el-table-column prop="diachi" label="Địa Chỉ" />
        <el-table-column prop="ma_tinh" label="Mã Tỉnh" />
        <el-table-column prop="ma_quan_huyen" label="Mã Quận/Huyện" />
        <el-table-column prop="ten_tinh" label="Tên Tỉnh" />
        <el-table-column prop="ten_quan_huyen" label="Tên Quận/Huyện" />
      </el-table>
    </el-scrollbar>
  </div>

  <!-- Tab VP Đại diện -->
  <div v-if="tabDetailCompany == 'vp_daidien'">
    <el-scrollbar class="height-table">
      <el-table :data="editTargetCompany.vp_daidien" border :row-class-name="isMainClass" >
        <el-table-column type="index" label="STT" width="70" align="center" />
        <el-table-column prop="mst" align="center" label="MST" width="150"/>
        <el-table-column prop="ten_thanh_vien" label="Tên Thành Viên" />
        <el-table-column prop="diachi" label="Địa Chỉ" />
        <el-table-column prop="ma_tinh" label="Mã Tỉnh" />
        <el-table-column prop="ma_quan_huyen" label="Mã Quận/Huyện" />
        <el-table-column prop="ten_tinh" label="Tên Tỉnh" />
        <el-table-column prop="ten_quan_huyen" label="Tên Quận/Huyện" />
      </el-table>
    </el-scrollbar>
  </div>

  <!-- Tab loại thuế cần nộp -->
  <div v-if="tabDetailCompany == 'loai_thue_can_nop'">
    <el-scrollbar class="height-table">
      <el-table :data="editTargetCompany.loai_thue_can_nop" border :row-class-name="isMainClass">
        <el-table-column type="index" label="STT" width="70" align="center" />
        <el-table-column prop="loai_thue_phai_nop.ma" align="center" label="Mã" width="70"/>
        <el-table-column prop="loai_thue_phai_nop.ten" label="Tên" />
      </el-table>
    </el-scrollbar>
  </div>

  <!-- Tab Ngành nghề -->
  <div v-if="tabDetailCompany == 'nganhNghe'">
    <el-scrollbar class="height-table">
      <el-table :data="editTargetCompany.ma_nganh_nghe" border :row-class-name="isMainClass">
        <el-table-column type="index" label="STT" width="70" align="center" />
        <el-table-column prop="nganh_kinh_doanh.code" align="center" label="Mã" width="70"/>
        <el-table-column prop="nganh_kinh_doanh.name" label="Ngành Nghề" />
      </el-table>
    </el-scrollbar>
  </div>

</el-dialog>
